<template>
  <studentTestReview
      v-if="reviewMode"
      :entity="testResult"
  />
  <dash-page
      v-else
      :title="testResult.test_name"
      :subtitle="$t('TestingProcess')"
      :mobile-title="testResult.test_name"
      icon="mdi-format-list-checks"
      :no-side="!reviewMode"
  >

    <template #default>


      <v-fade-transition mode="out-in">
        <!-- Main content-->
        <div v-if="!loading">

          <div v-if="reviewMode">

            <div class="overflow-auto" >
              <v-sheet  class=" pa-6 wsRoundedHalf mt-5" min-height="500"
                        :set="question = testResult.questions[selectedQuestion]"
                        :key="i + '_question'"
                        :ref="'question_' + i"

              >
                <v-sheet min-height="400" class="fill-height">
                  <!-- Question Header-->
                  <div class="d-flex justify-space-between mb-3">
                    <div class="d-flex align-center">
                      <h4>{{ $t('Question')}} {{ selectedQuestion+1 }}</h4>
                    </div>
                    <div class="d-flex">

                      <v-chip outlined :color="wsDARKER" small class="mr-2" v-bind="attrs" v-on="on">
                        <v-icon x-small>mdi-cog</v-icon>
                        {{ $t('Points') }} : {{ question.points }}
                      </v-chip>

                    </div>
                  </div>

                  <!-- Question Content-->
                  <wsTextViewer
                      :value="question.content"
                      :html="question.content_text"

                      student
                      class="pb-6"
                  />
                  <!-- Question Answers -->
                  <div v-if="question.type !== 'true_false' " class="mt-3"  >
                    <h5>{{ $t('Answers:')}}</h5>
                    <!-- Answers List -->
                    <div v-for="(answer,j) in question.answers" :key="j + '_answer'">
                      <!-- Answers List -->
                      <table width="100%">
                        <tr>
                          <!-- Check Box -->
                          <td width="10px" valign="top" align="top">
                            <v-btn v-if="question.type === 'default' " @click="answer.correct = !answer.correct ; "
                                   :color="getAnswerColor(answer) "
                                   icon
                                   class="mt-1">
                              <v-icon >{{ answer.reply ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'}}</v-icon>
                            </v-btn>
                            <h5 class="mt-3 font-weight-light" v-if="question.type === 'order'">{{ j+1 }}</h5>
                          </td>
                          <!-- Content -->
                          <td>
                            <div :style="`background: ${getAnswerColor(answer)};
                                color : ${getAnswerColor(answer) ? 'white' : ''} `"
                                 class="px-3 py-1 mt-2 pointer"
                                 v-ripple style=" border-radius: 5px"
                                 >

                            <wsTextViewer
                                :value="answer.content"
                                :html="answer.content_text"
                                student
                            />
                            </div>

                          </td>
                        </tr>
                      </table>
                    </div>

                  </div>
                  <!-- True/False Buttons-->
                  <div v-if="question.type === 'true_false' " class="d-flex" >
                    <div style="width: 50%">
                      <v-btn
                             :color="question.question_reply_bool === true && question.correct ? wsSUCCESS : wsWARNING"
                             :text="question.question_reply_bool === null || question.question_reply_bool === false"
                             :dark="question.question_reply_bool === true"
                             elevation="0"
                             class="noCaps mt-3 mx-1"
                             block
                      >
                        <v-icon>mdi-plus</v-icon>
                        {{ $t('True')}}
                      </v-btn>
                    </div>
                    <div style="width: 50%">
                      <v-btn
                             :color="question.question_reply_bool === false && question.correct ? wsSUCCESS : wsWARNING"
                             :text="question.question_reply_bool === null || question.question_reply_bool === true"
                             :dark="question.question_reply_bool === false"
                             class="noCaps mt-3 mx-1"
                             block >
                        <v-icon>mdi-minus</v-icon>
                        {{ $t('False')}}
                      </v-btn>
                    </div>
                  </div>
                </v-sheet>



                <!-- Navigation Buttons -->
                <div class="d-flex justify-space-between mt-6">
                  <div v-if="selectedQuestion > 0" style="width: 100%" >
                    <v-btn  @click="selectedQuestion--" block class="noCaps" text :color="wsDARK">
                      <v-icon>mdi-chevron-left</v-icon>
                      {{ $t('GoBack') }}</v-btn>
                  </div>
                  <div v-if="selectedQuestion < testResult.questions.length - 1 " style="width: 100%">
                    <v-btn  @click="selectedQuestion++" block class="noCaps" text :color="wsDARK">
                      {{ $t('Next') }}
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </div>
                </div>

                <v-btn @click="reviewMode = false ;" :color="wsACCENT"
                       class="noCaps mt-3 ml-2"  block  outlined >
                  {{ $t('Back') }}
                </v-btn>

              </v-sheet>
            </div>
          </div>

          <!-- Test Result Screen-->
          <div v-if="!reviewMode">
            <!-- Test Result Screen-->
            <v-sheet
                :style="`border : 1px solid ${wsBACKGROUND}`"
                :color="wsLIGHTCARD"
                class=" wsRoundedHalf mt-6">

              <v-sheet :color="testResult.status === 'success' ? wsSUCCESS : wsWARNING" dark class="d-flex justify-space-between align-center wsRoundedHalfTop py-2 px-6">
                <v-icon class="mr-3">mdi-format-list-checks</v-icon>
                <h4 class="font-weight-medium">{{$t('TestFinished')}}</h4>
                <div />
              </v-sheet>



              <div class="pa-6">
                <h4 v-if="!SM" class="text-center font-weight-medium mt-5">{{ testResult.status === 'success' ? $t('TestMessageSuccess') : $t('TestMessageFail')}}</h4>
                <h5 v-else  class="text-center font-weight-medium mt-5">{{ testResult.status === 'success' ? $t('TestMessageSuccess') : $t('TestMessageFail')}}</h5>

                <v-row  class="mt-3">

                  <v-col cols="4" sm="4" md="4" lg="4" xl="4">
                    <v-sheet color="transparent" :class="!SM ? 'py-6' : '' ">
                      <h1 :style="`color:${wsDARK}`" class="text-center">{{ testResult.points }}</h1>
                      <h5 class=" text-center">{{$t('Points')}}</h5>
                    </v-sheet>
                  </v-col>
                  <v-col  cols="4" sm="4" md="4" lg="4" xl="4" >
                    <v-sheet color="transparent" :class="!SM ? 'py-6' : '' ">
                      <h1 :style="`color:${wsSUCCESS}`" class="text-center">{{ testResult.correct }}</h1>
                      <h5 class=" text-center">{{$t('CorrectAnswers')}}</h5>
                    </v-sheet>
                  </v-col>
                  <v-col  cols="4" sm="4" md="4" lg="4" xl="4" >
                    <v-sheet color="transparent" :class="!SM ? 'py-6' : '' ">
                      <h1 :style="`color:${wsWARNING}`" class="text-center">{{ testResult.wrong }}</h1>
                      <h5 class=" text-center">{{$t('WrongAnswers')}}</h5>
                    </v-sheet>
                  </v-col>
                </v-row>

                <h4 v-if="!testResult.test_unlimited" class="text-center font-weight-light mt-5">
                  <v-icon :color="wsDARK" small class="mb-1">mdi-reload</v-icon>
                  {{$t('TriesLeft')}}:
                  <span class="font-weight-bold">{{ testResult.tries_left }}</span>
                </h4>

                <div class="justify-center mt-6"
                     :class="!SM ? 'd-flex' : '' ">
                  <v-btn v-if="testResult.tries_left > 0 || testResult.test_unlimited"
                         :to="businessDashLink('courses/test/' + testResult.test_id)"
                         :block="SM"
                         :color="wsDARK"
                         class="noCaps"  text >
                    {{ $t('TryAgain') }}</v-btn>
                  <v-btn @click="reviewMode = true" :block="SM" class="noCaps" text :color="wsDARK">{{ $t('ReviewTest') }}</v-btn>
                </div>

                <div class="d-flex justify-center mt-5 mb-3">
                  <v-btn :to="businessDashLink('courses/component/' + testResult.component_id)" class="noCaps px-16" outlined :color="wsDARK">{{ $t('Finish') }}</v-btn>
                </div>

              </div>


            </v-sheet>
          </div>


        </div>
        <!-- Loading-->
        <v-sheet v-else class="wsRoundedHalf pa-16 mt-3 d-flex justify-center align-center" style="height: 92%;" >
          <div class="d-flex align-center">
            <v-progress-circular  indeterminate class="mr-5" :color="wsDARK"/>
            <h2 class="font-weight-light text-center" :style="`color: ${wsDARK}`">{{$t('Loading')}}</h2>
          </div>
        </v-sheet>
      </v-fade-transition>


    </template>
    <template v-slot:side="{collapse}">

      <div :class="SM ? 'px-3' : '' ">
        <!-- Questions Navigation-->
        <h5 class="mt-5">{{ $t('Navigation') }}:</h5>
        <div class="pr-6">
          <v-row  class="mt-1">

            <v-col v-for="(question,i) in testResult.questions" :key="i + 'qNavigation'"
                   class="py-0 pb-1"
                   cols="2">
              <v-btn  @click="selectedQuestion = i; collapse()"
                      :style="selectedQuestion === i ? 'border: 1px solid white' : ''"
                      :outlined="!hasReply(question) && selectedQuestion !== i"
                      :color="getNavigationColor(question)"
                      min-width="42" width="42"
                      class="noCaps"
                      elevation="0"
                      small  > {{ i+1 }}</v-btn>
            </v-col>

            <v-btn @click="reviewMode = !reviewMode ; collapse()"
                   class="noCaps mt-3 ml-2"
                   block
                   outlined >
              {{ !reviewMode ? $t('ReviewTest') :  $t('Back') }}
            </v-btn>

          </v-row>
        </div>
      </div>


    </template>
  </dash-page>

</template>

<script>
import {mapActions} from "vuex";
import studentTestReview from "@/components/pages/westudy/tests/studentTestReview";
export default {
  name: "studentTestResult",
  props : ['uuid'],
  components : {
    studentTestReview
  },
  data() {
    return {
      loading : true,
      testResult : {},
      reviewMode : false,
      selectedQuestion : 0,
    }
  },
  methods : {
    ...mapActions('courses', [
      'FINISH_TEST'
    ]),

    //technical
    getAnswerColor(answer) {
      if ( answer.correct  ) { return this.wsSUCCESS }
      if ( answer.reply && !answer.correct ) { return this.wsWARNING }
      return ''
    },
    getNavigationColor(question) {

      return  this.hasReply(question) ? this.wsSUCCESS : this.wsWARNING
    },
    hasReply(question) {

      if (question.type === 'default' && !question.multiple_type ) {
        let hasAnswer = question.answers.find( el=> el.reply === true )
        return  !!hasAnswer.correct
      } else if ( question.type === 'default' && question.multiple_type ) {
        let correctAnswersUuids = question.answers.filter( el=> el.correct === true ).map(el => el.uuid)
        let studentReplies = question.answers.filter( el=> el.reply === true ).map(el => el.uuid)
        if ( studentReplies.length !== correctAnswersUuids.length ) {
          return false
        }

        return  studentReplies.filter(el => correctAnswersUuids.includes(el) ).length === correctAnswersUuids.length
      }
      if (question.type === 'true_false' ) {
        return  question.question_reply_bool !== null
      }

      return  false

    },

    initPage(){
      if ( this.uuid ) {
        this.loading = true
        this.FINISH_TEST(this.uuid).then((out)=>{
          if ( out.result ) {
            this.testResult = out.data
            this.testCompleted = true
          }
          this.loading = false
        }).catch(()=> this.loading = false )
      }

    },

  },
  mounted()  {
    this.initPage()
  }
}
</script>

<style scoped>

</style>