<template>

  <!-- DESIGN 1:  Test Next / Prev Buttons navigation-->
  <div    v-if="!dropdown">

    <v-sheet
        v-if="!displayNext && !allAnswered && !preview"
        class="px-4 py-2 my-5" :style="`border : 1px solid ${wsBACKGROUND}`"
        style="border-radius: 8px;"
    >
      <h5 class="d-flex align-center" >
        <v-icon :color="wsATTENTION" class="mr-2">mdi-information</v-icon>
        {{ $t('NotAllQuestionsAnswered') }}</h5>
    </v-sheet>

    <v-sheet

        class="  d-flex"
        :class="[{'justify-end' : !displayPrev}]"
    >

      <!-- Previous Button-->
      <v-sheet
          v-if="displayPrev"
          @click="previous"
          :style="`border : 1px solid ${wsBACKGROUND}`"
          class="d-flex align-center"
          :class="[
          { 'pa-4' : !SM },
          { 'pa-2' : SM },
          {'wsRoundedLightLeft' : displayNext && !SM },
          {'wsRoundedSemi' : !displayNext && !SM },
          {'pointer' : displayPrev }
        ]"
          width="50%">
        <v-icon :color="displayPrev ? wsACCENT : 'grey'" class="mr-5">mdi-arrow-left</v-icon>
        <h4  :style="`color : ${displayPrev ? wsACCENT : 'grey'}`"> {{ $t('GoBack') }}</h4>
      </v-sheet>
      <!-- Next/Finsih Test Button-->
      <v-sheet
          v-if="!preview || (preview && displayNext)"
          @click="next"
          class="d-flex justify-end align-center pointer"
          :color="displayNext || allAnswered ? wsATTENTION : 'grey' "
          dark
          :class="[
                  { 'pointer' : displayNext || (!displayNext && allAnswered) },
                  { 'pa-4' : !SM },
                  { 'pa-2' : SM },
                  {'wsRoundedLightRight' : !SM },
                  {'wsRoundedSemi' : !displayPrev || SM },
                  {'pointer' : displayNext }
              ]"
          width="50%">

        <div>
          <h4 class="text-right">
            {{ !displayNext ? $t('FinishTest') : $t('Next') }}
          </h4>
        </div>

        <v-icon v-if="displayNext || allAnswered" class="ml-5">mdi-arrow-right</v-icon>
      </v-sheet>

    </v-sheet>


  </div>


  <!-- DESIGN 2: Test Header Navigation Dropdown-->
  <v-menu
      v-else
      offset-y :close-on-content-click="false">

    <!-- Activator Button -->
    <template #activator="{ on, attrs }">

      <v-sheet v-on="on" v-bind="attrs" :class="[{'mt-5' : SM}]" :width="SM ? '100%' : null">

        <v-sheet
            v-if="!allAnswered || SM"
            :style="`border : 1px solid ${wsDARKLIGHT}`"

            class="wsRoundedLight d-flex justify-space-between py-2 px-2"
        >
          <v-hover #default="{hover}">
            <v-sheet
                @click.stop="previous"
                :color="previousColor(hover)"  style="border-radius: 50%"
                :class="[{pointer : displayPrev}]"
            >
              <v-icon :color="displayPrev ? wsDARK : wsDARKLIGHT">mdi-arrow-left</v-icon>
            </v-sheet>
          </v-hover>

          <h5 class="d-flex align-center justify-center wsDARKER" style="width: 180px">
            <span>{{ $t('Question') }} {{ selectedQuestion + 1 }} / {{ questions.length }}</span>
            <v-icon :color="wsDARKER">mdi-menu-down</v-icon>
          </h5>

          <v-hover #default="{hover}">
            <v-sheet
                @click.stop="next"
                :color="nextColor(hover)" style="border-radius: 50%"
                :class="[{pointer : displayNext}]"
            >
              <v-icon :color="displayNext ? wsDARK : wsDARKLIGHT">mdi-arrow-right</v-icon>
            </v-sheet>
          </v-hover>
        </v-sheet>
        <v-btn
            v-else
            v-on="on"
            v-bind="attrs"
            :color="wsACCENT"
            icon
        >
          <v-icon>mdi-format-list-bulleted</v-icon>
        </v-btn>
      </v-sheet>

    </template>

    <!-- All questions navigation dropdown -->
    <v-sheet class="pa-2 d-flex  flex-wrap" max-width="238" >
      <v-sheet
          v-for="(question,i) in questions" :key="i + 'qNavigation'"
          @click="selectedQuestion = i;"
          :style="getNavigationStyle(question,i)"
          class="d-flex align-center justify-center pointer"
          height="32" width="32"
          style="margin: 2.5px"
          v-ripple
      >
        {{ i + 1 }}
      </v-sheet>
    </v-sheet>

  </v-menu>

</template>

<script>
export default {
  name: "testNavigation",
  props : {
    value : {
      type : Number,
      default : 0
    },
    questions : {
      type : Array,
      default() { return [] }
    },
    allAnswered : {
      type : Boolean,
      default : false
    },
    dropdown : {
      type : Boolean,
      default : false
    },
    preview : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      selectedQuestion : 0
    }
  },
  watch : {
    value() {
      if ( this.value !== this.selectedQuestion ) {
        this.selectedQuestion = this.value
      }
    },
    selectedQuestion() {
      if ( this.value !== this.selectedQuestion ) {
        this.$emit('input' , this.selectedQuestion)
      }
    },
  },
  computed : {
    displayNext() {
      return (this.selectedQuestion < (this.questions.length - 1)) || this.selectedQuestion === 0
    },
    displayPrev() {
      return this.selectedQuestion > 0
    }
  },
  methods : {


    hasReplyPreview(question) {

      if (question.type === 'default' && !question.multiple_type ) {
        let hasAnswer = question.answers.find( el=> el.reply === true )
        return  !!hasAnswer.correct
      } else if ( question.type === 'default' && question.multiple_type ) {
        let correctAnswersUuids = question.answers.filter( el=> el.correct === true ).map(el => el.uuid)
        let studentReplies = question.answers.filter( el=> el.reply === true ).map(el => el.uuid)
        if ( studentReplies.length !== correctAnswersUuids.length ) {
          return false
        }

        return  studentReplies.filter(el => correctAnswersUuids.includes(el) ).length === correctAnswersUuids.length
      }
      if (question.type === 'true_false' ) {
        return  question.question_reply_bool !== null
      }

      return  false

    },

    hasReply(question) {

      if ( this.preview ) {
        return this.hasReplyPreview(question)
      }

      if (question.type === 'default' ) {
        let hasAnswer = question.answers.find( el=> el.reply === true )
        return  !!hasAnswer
      }
      if (question.type === 'true_false' ) {
        return  question.question_reply_bool !== null
      }

      return false
    },

    previous() {
      if (this.displayPrev) {
        this.selectedQuestion--
      }
    },
    next() {
      if ( !this.dropdown && !this.displayNext && this.allAnswered ) {
        this.$emit('finish')
        return
      }

      if (this.displayNext ) {
        this.selectedQuestion++
      }

    },
    previousColor(hover) {
      if ( !this.displayPrev ) {
        return this.wsLIGHTCARD
      }
      return hover ? this.wsBACKGROUND : 'transparent'
    },
    nextColor(hover) {
      if ( !this.displayNext ) {
        return this.wsLIGHTCARD
      }
      return hover ? this.wsBACKGROUND : 'transparent'
    },
    getNavigationStyle(question,i) {

      let borderColor = this.selectedQuestion === i ? this.wsACCENT :  this.wsBACKGROUND
      let style = `border : 1px solid ${borderColor}; font-size : 13px; font-weight : 600;`
      let color = this.wsACCENT

      if ( !this.preview ) {
        if ( this.selectedQuestion === i ) {
          color = '#ffffff'
          style += `background-color : ${this.wsACCENT};`
        } else if (this.hasReply(question)) {
          color = this.wsDARKER
          style += `background-color : ${this.wsDARKLIGHT};`
        }
      } else {
        color = '#ffffff'
        style += `background-color : ${this.hasReplyPreview(question) ? this.wsSUCCESS : this.wsWARNING};`
      }




      style += `color : ${color};` ;

      return style
    },
  }
}
</script>

<style scoped>

</style>